
.v-select::v-deep .v-input__control {
    max-height: 20px !important;
}
.ministry-pages::v-deep .v-select__selection--comma {
    color: #78756a;
}

.announcement-list {
    overflow: auto;

    &__pagination {
        padding: 1rem;
    }
}
